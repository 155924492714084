<template>
    <v-container fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <v-card class="elevation-12">
                    <v-toolbar color="yellow">
                        <v-toolbar-title
                            >Reenvía email de confirmación</v-toolbar-title
                        >
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-text-field
                                prepend-icon="mdi-email"
                                name="email"
                                label="Email"
                                type="email"
                                v-model="email"
                                :rules="emailRules"
                                data-cy="joinEmailField"
                                required
                            ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="yellow"
                            :disabled="!valid"
                            to="/"
                            data-cy="joinSubmitBtn"
                            class="text-none"
                            >Cancela</v-btn
                        >
                        <v-btn
                            color="yellow"
                            :disabled="!valid"
                            @click="submit"
                            data-cy="joinSubmitBtn"
                            class="text-none"
                        >
                            Confirma
                            <span>
                                <v-progress-circular
                                    indeterminate
                                    v-if="progress"
                                >
                                </v-progress-circular>
                            </span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
            valid: false,
            email: '',
            emailRules: [
                v => !!v || 'Se requiere dirección de E-mail!',
                v => /.+@.+/.test(v) || 'E-mail debe ser válido'
            ],
            loading: false
        };
    },
    computed: {
        ...mapState('account', ['progress'])
    },
    methods: {
        ...mapActions('account', ['ResendConfirmationEmail']),
        submit() {
            this.loading = true;
            if (this.$refs.form.validate()) {
                this.ResendConfirmationEmail(this.email);
            }
            this.loading = false;
            this.$router.push('/');
        }
    }
};
</script>
